import type { MetaFunction } from '@remix-run/cloudflare';
import { Link } from '@remix-run/react';
import { Trans, useTranslation } from 'react-i18next';
import asyLogoImgUrl from '~/assets/images/brand-logos/asy-logo.png';
import bahudhaFoundationLogoImgUrl from '~/assets/images/brand-logos/bahudha-foundation-logo.png';
import sadafalEducationLogoImgUrl from '~/assets/images/brand-logos/sadafal-education-logo.png';
import asyActivityIdy2024ImgUrl from '~/assets/images/homepage/asy-activities/idy-delhi-2024.png';
import asyActivityIdyMauImgUrl from '~/assets/images/homepage/asy-activities/idy-mau-2023.jpeg';
import asyActivityIncredibleIndiaImgUrl from '~/assets/images/homepage/asy-activities/paryatan-parv-2022.jpeg';
import kumbhGallery1ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery1.jpeg';
import kumbhGallery2ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery2.jpeg';
import kumbhGallery3ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery3.jpeg';
import kumbhGallery4ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery4.jpeg';
import kumbhGallery5ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery5.jpeg';
import kumbhGallery6ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery6.jpeg';
import kumbhGallery7ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery7.jpeg';
import teamMemberGangadharImgUrl from '~/assets/images/homepage/team-members/gangadhar.jpg';
import teamMemberGopalImgUrl from '~/assets/images/homepage/team-members/team-member-gopal.jpeg';
import teamMemberNaamDeoImgUrl from '~/assets/images/homepage/team-members/team-member-naamdeo.png';
import teamMemberRobinImgUrl from '~/assets/images/homepage/team-members/team-member-robin.png';
import naamdeoSignatureBlackImgUrl from '~/assets/images/naamdeo-signature-black.png';
import embraceKumbhTestimonialPreviewImgUrl from '~/assets/images/testimonials/embrace-kumbh.png';
import { BrandCard } from '~/components/brand-card';
import { CERTIFICATION_DATA, Certifications } from '~/components/certifications';
import { ContentSection } from '~/components/content-section';
import { ImageCarousel } from '~/components/image-carousel';
import { Button } from '~/components/ui/button';
import { Separator } from '~/components/ui/separator';
import { Typography, Emphasize, DashedText } from '~/components/ui/typography';
import { CF_IMAGE_URLS } from '~/lib/cf-image-urls';
import { getSeoMeta } from '~/lib/seo';
import { AsyBrandCard } from './asy-brand-card';
import { AsyFeatureCard } from './asy-feature-card';
import { ImageGrid } from './image-grid';
import { SocialServicesFeatureCard } from './social-services-feature-card';
import { SubscribeForm } from './subscribe-form';
import { SukritSocialRow } from './sukrit-social-row';
import { TeamFeatureCard } from './team-feature-card';
import { TestimonialGrid } from './testimonial-grid';
import { TestimonialSwitcher } from './testimonial-switcher';
import { VanityFeature } from './vanity-feature';

export const handle = {
  i18n: ['common', 'home'],
};

export const meta: MetaFunction = (args) => {
  return getSeoMeta(
    {
      title: 'Sukrit Wellness',
      description: 'Transformative Wellness Experiences',
    },
    args
  );
};

export default function Index() {
  const { t } = useTranslation(['common', 'home']);
  const currentYear = new Date().getFullYear();

  return (
    <div className="relative pt-12">
      <nav className="absolute top-3 flex w-full flex-row justify-center px-[2%] lg:top-12 lg:justify-between lg:px-[5%]">
        <Typography className="text-xs uppercase tracking-widest sm:text-sm">
          <Link to="#kumbh-mela">{t('home:nav.kumbh')}</Link> •{' '}
          <Link to="#service-partners">{t('home:nav.partners')}</Link> •{' '}
          <Link to="#our-team">{t('home:nav.team')}</Link>
        </Typography>
        <SukritSocialRow className="hidden lg:flex" />
      </nav>
      <section>
        <div className="flex flex-col items-center">
          <img src={CF_IMAGE_URLS['sukritwellness-logo-blue']} alt="Sukrit Wellness" className="h-28 w-28" />
          <DashedText className="w-[90%] md:w-[80%]">
            <Typography variant="h1" className="mx-5 mt-4 md:px-10">
              {t('home:title')}
            </Typography>
          </DashedText>
          <Typography variant="tagline" className="mt-1.5 text-gray-500 lg:text-sm">
            {t('home:tagline')}
          </Typography>
        </div>
        <div className="relative my-12 h-[50vh] md:h-[70vh]">
          <img className="absolute h-full w-full object-cover" src={CF_IMAGE_URLS['hero-cover']} alt="Kumbh 2025" />
          <div className="flex h-full w-full flex-col items-center justify-center p-4 text-center md:absolute md:bottom-16 md:left-40 md:h-auto md:w-auto md:items-start md:justify-start md:p-0 md:text-left [&>*]:z-10">
            <Typography variant="h1" as="h2" className="mb-2 font-fraunces font-bold text-white">
              {t('home:hero.title')}
            </Typography>
            <Typography variant="h3" as="p" className="text-balance text-white">
              <Trans i18nKey="home:hero.tagline">
                Join the largest congregation on Earth
                <br className="hidden md:block" />
                along with Sukrit Wellness Tours
              </Trans>
            </Typography>
            <div className="mt-8 flex space-x-4">
              <div
                className=""
                onClick={() => {
                  document.getElementById('book-now')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
                }}
              >
                <Button className="text-xs md:text-base">{t('common:book-now')}</Button>
              </div>
              <Link to="#kumbh-mela" className="scroll-smooth">
                <Button className="text-xs md:text-base" variant="secondary">
                  {t('common:learn-more')}
                </Button>
              </Link>
            </div>
          </div>
          <div className="absolute bottom-0 left-1/2 h-1 w-[80px] -translate-x-1/2 bg-primary md:h-2 md:w-[120px]"></div>
        </div>
      </section>
      <ContentSection className="flex flex-col items-center">
        <Typography variant="h3" as="p" className="text-pretty text-center">
          <Trans i18nKey="home:about.mission">
            True wellness entails <Emphasize>deeply</Emphasize> transformational, <Emphasize>soul-lifting</Emphasize>{' '}
            experiences <br className="hidden md:block" /> catapulting us towards our{' '}
            <Emphasize> best selves</Emphasize>. At Sukrit Wellness we promise to <br className="hidden md:block" />{' '}
            leave you physically, mentally, socially and spiritually <Emphasize> recharged</Emphasize>.
          </Trans>
        </Typography>
        <Typography variant="mutedText" className="mt-8 text-pretty text-center text-xl font-normal">
          <Trans i18nKey="home:about.tagline">
            Sukrit Wellness encompasses a diverse range offerings, each dedicated to <br className="hidden md:block" />{' '}
            delivering specialized, holistic wellness solutions.
          </Trans>
        </Typography>
      </ContentSection>
      <section className="my-12">
        <div>
          <Certifications certifications={CERTIFICATION_DATA} />
        </div>
      </section>
      <div className="mx-auto mb-14 mt-12 w-[90%] md:w-4/5">
        <Separator />
      </div>
      <div className="flex flex-col lg:flex-row">
        <Typography variant="tagline" className="mb-4 block px-4 text-center font-bold tracking-widest lg:hidden">
          {t('home:founder.heading')}
        </Typography>
        <div className="aspect-[16/13] w-full lg:w-2/5">
          <ImageCarousel
            imageUrls={[
              CF_IMAGE_URLS['founder-gallery-1'],
              CF_IMAGE_URLS['founder-gallery-2'],
              CF_IMAGE_URLS['founder-gallery-3'],
            ]}
          />
        </div>
        <div className="flex w-full items-center lg:w-1/2">
          <div>
            <Typography variant="tagline" className="mb-6 hidden pl-20 font-bold tracking-widest lg:block">
              {t('home:founder.heading')}
            </Typography>
            <div className="rounded-lg bg-accent p-4 py-10 lg:px-20">
              <p className="mb-1.5 text-4xl">❝</p>
              <Typography variant="h2" as="p" className="font-fraunces">
                <Trans i18nKey="home:founder.quote">
                  No matter where we reach, the idea of ‘we’ as <Emphasize>‘who we are’</Emphasize> remains the key to
                  our well-being.
                </Trans>
              </Typography>
              <img src={naamdeoSignatureBlackImgUrl} alt="Naamdeo" className="mb-2 mt-8 max-h-6" />
              <Typography variant="tagline" className="font-normal">
                {t('home:founder.position')}
              </Typography>
              <Separator className="my-8" />
              <div>
                <a href="https://www.naamdeo.org" className="font-fraunces text-lg text-blue-700">
                  naamdeo.org
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Kumbh Section */}
      <div className="mb-12 mt-24" id="kumbh-mela">
        <ContentSection className="text-center">
          <Typography variant="h2">{t('home:kumbh-2025.title')}</Typography>
          <Typography variant="h4" as="p" className="mt-2">
            {t('home:kumbh-2025.tagline')}
          </Typography>
        </ContentSection>
        <div className="mt-8 flex flex-col justify-center gap-12 px-4 lg:flex-row lg:px-0">
          <BrandCard
            className="lg:w-[40%]"
            logoImgUrl={CF_IMAGE_URLS['kumbh-sukrit-logo-black']}
            backgroundImgUrl={CF_IMAGE_URLS['kumbh-sukrit-brand-card']}
            title={t('home:kumbh-2025.ks-card.title')}
            tagline={
              <span>
                <Trans i18nKey="home:kumbh-2025.ks-card.tagline">
                  EXPERIENCE KUMBH @{' '}
                  <a className="underline" href="https://kumbhsukrit.com/#sanskriti" target="_blank">
                    SANSKRITI VANN
                  </a>
                </Trans>
              </span>
            }
            description={t('home:kumbh-2025.ks-card.description')}
            learnMoreUrl="https://kumbhsukrit.com/"
            websiteUrl="https://kumbhsukrit.com/"
            facebookUrl="https://www.facebook.com/kumbhsukrit"
            instagramUrl="https://www.instagram.com/kumbhsukrit/"
          />
          <BrandCard
            className="lg:w-[40%]"
            logoImgUrl={CF_IMAGE_URLS['camp67-logo-white']}
            backgroundImgUrl={CF_IMAGE_URLS['camp-67-brand-card']}
            title={t('home:kumbh-2025.camp67-card.title')}
            tagline={t('home:kumbh-2025.camp67-card.tagline')}
            description={t('home:kumbh-2025.camp67-card.description')}
            learnMoreUrl="https://camp67.com/"
            websiteUrl="https://camp67.com/"
            facebookUrl="https://www.facebook.com/camp67"
            instagramUrl="https://www.instagram.com/camp67/"
          />
        </div>
        <ContentSection className="mt-20 text-center">
          <Typography variant="h4" as="p" className="font-sans">
            <Trans i18nKey="home:kumbh-2025.why-us">
              We have been hosting Kumbh for <strong>over a decade</strong>, with a highly specialized team
            </Trans>
          </Typography>
        </ContentSection>
        <div
          className="mt-8 flex flex-col justify-center gap-8 py-20 text-center md:flex-row"
          style={{ background: 'linear-gradient(90deg, #CFFFEB 0%, #B2FFDF 100%)' }}
        >
          <VanityFeature
            title={t('home:kumbh-2025.vanity-features.guests.count')}
            tagline={t('home:kumbh-2025.vanity-features.guests.tagline')}
            description={t('home:kumbh-2025.vanity-features.guests.description')}
          />
          <VanityFeature
            title={t('home:kumbh-2025.vanity-features.countries.count')}
            tagline={t('home:kumbh-2025.vanity-features.countries.tagline')}
            description={t('home:kumbh-2025.vanity-features.countries.description')}
          />
          <VanityFeature
            title={t('home:kumbh-2025.vanity-features.followers.count')}
            tagline={t('home:kumbh-2025.vanity-features.followers.tagline')}
            description={t('home:kumbh-2025.vanity-features.followers.description')}
          />
        </div>
        <div className="px-4 py-14 lg:px-0">
          <TestimonialSwitcher
            testimonials={[
              {
                embedUrl: 'https://www.youtube.com/embed/i8LXte3EFPg?si=DCq9T-RD_JbH0u5V',
                previewImgUrl: CF_IMAGE_URLS['ks-testimonial-valarie-thumbnail'],
                title: t('home:kumbh-2025.testimonials.valerie.title'),
                description: t('home:kumbh-2025.testimonials.valerie.description'),
              },
              {
                embedUrl: 'https://www.youtube.com/embed/XUIs_7__LKY?si=4LTokDxcr5WwAHi1',
                previewImgUrl:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/c9b4e46d-e6b6-45a5-b2b2-60d64b9b2a00/public',
                title: t('home:kumbh-2025.testimonials.montage.title'),
                description: t('home:kumbh-2025.testimonials.montage.description'),
              },
              {
                embedUrl: 'https://www.youtube.com/embed/9zBCiOi9mD8?si=kX45NjiXcCDs43pt',
                previewImgUrl: embraceKumbhTestimonialPreviewImgUrl,
                title: t('home:kumbh-2025.testimonials.embrace-kumbh.title'),
                description: t('home:kumbh-2025.testimonials.embrace-kumbh.description'),
              },
              {
                embedUrl: 'https://www.youtube.com/embed/1EATgDuQ0io?si=quk0f7h05gZzEhS1',
                previewImgUrl: 'https://i3.ytimg.com/vi/1EATgDuQ0io/maxresdefault.jpg',
                title: t('home:kumbh-2025.testimonials.naam-deo.title'),
                description: t('home:kumbh-2025.testimonials.naam-deo.description'),
              },
            ]}
          />
        </div>
        <ContentSection>
          <TestimonialGrid
            testimonials={[
              {
                imageSrc:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/966e715a-16e2-477d-1d5c-f9f406c99b00/public',
                imageAlt: t('home:kumbh-2025.testimonials.amit-swapna.imageAlt'),
                quote: t('home:kumbh-2025.testimonials.amit-swapna.quote'),
                author: {
                  name: 'Amit & Swapna Pawar',
                  location: t('home:kumbh-2025.testimonials.amit-swapna.location'),
                  videoSrc: 'https://www.youtube.com/embed/SeRjwcdlVFQ?si=XrmtphSJorb5A60d',
                },
              },
              {
                imageSrc:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/7ff2bd53-aacc-4472-2091-c5ef8c31d000/public',
                imageAlt: t('home:kumbh-2025.testimonials.richard-singh.imageAlt'),
                quote: t('home:kumbh-2025.testimonials.richard-singh.quote'),
                author: {
                  name: 'Richard Singh',
                  location: t('home:kumbh-2025.testimonials.richard-singh.location'),
                  videoSrc: 'https://www.youtube.com/embed/wqfyTic9L7E?si=oasCEFFQhDGV-2Kh',
                },
              },
              {
                imageSrc:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/8699fcd3-8b83-47da-a1ed-e61cce9a2000/public',
                imageAlt: t('home:kumbh-2025.testimonials.rajesh-mittal.imageAlt'),
                quote: t('home:kumbh-2025.testimonials.rajesh-mittal.quote'),
                author: {
                  name: 'Rajesh Mittal',
                  location: t('home:kumbh-2025.testimonials.rajesh-mittal.location'),
                  videoSrc: 'https://www.youtube.com/embed/5tSohpCNSJ8?si=i6aZ3A-FZ2Qu2kio',
                },
              },
              {
                imageSrc:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/b194211e-043f-41c4-53c8-3ea94cc9c300/public',
                imageAlt: t('home:kumbh-2025.testimonials.gabriella-burnel.imageAlt'),
                quote: t('home:kumbh-2025.testimonials.gabriella-burnel.quote'),
                author: {
                  name: 'Gabriella Burnel',
                  location: t('home:kumbh-2025.testimonials.gabriella-burnel.location'),
                  videoSrc: 'https://www.youtube.com/embed/LygBGodZrk4?si=LcchA0Zg_w88lmH9',
                },
              },
            ]}
          />
        </ContentSection>
        <div className="mx-auto my-12 w-[90%] md:w-4/5">
          <Separator />
        </div>
        <div>
          <ImageGrid
            columns={[
              [
                { src: kumbhGallery1ImgUrl, alt: 'Kumbh Gallery 1', className: '' },
                { src: kumbhGallery4ImgUrl, alt: 'Kumbh Gallery 4', className: '' },
              ],
              [
                { src: kumbhGallery2ImgUrl, alt: 'Kumbh Gallery 2', className: '' },
                { src: kumbhGallery5ImgUrl, alt: 'Kumbh Gallery 5', className: '' },
                { src: kumbhGallery7ImgUrl, alt: 'Kumbh Gallery 7', className: '' },
              ],
              [
                { src: kumbhGallery3ImgUrl, alt: 'Kumbh Gallery 3', className: '' },
                { src: kumbhGallery6ImgUrl, alt: 'Kumbh Gallery 6', className: '' },
                {
                  src: 'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/6ed2b97c-08b5-4f43-d2e4-664f82508a00/public',
                  alt: 'Kumbh Gallery 8',
                  className: '',
                },
              ],
            ]}
          />
          <ContentSection
            className="mt-12 flex flex-col items-center justify-center gap-4 md:gap-8 lg:flex-row"
            id="book-now"
          >
            <Button className="w-full px-9 py-10 md:w-auto md:p-10" asChild>
              <a href="https://kumbhsukrit.com/" target="_blank" rel="noreferrer">
                <div className="flex flex-col items-start justify-center text-center">
                  <Typography
                    as="span"
                    className="w-full font-fraunces font-bold leading-snug tracking-widest underline"
                  >
                    {t('home:kumbh-2025.visit-ks.cta')}
                  </Typography>
                  <Typography
                    variant="p"
                    as="span"
                    className="w-full text-wrap font-fraunces text-sm normal-case tracking-normal"
                  >
                    {t('home:kumbh-2025.visit-ks.description')}
                  </Typography>
                </div>
              </a>
            </Button>
            <Button variant="secondary" className="w-full px-4 py-10 md:w-auto md:p-10" asChild>
              <a href="https://camp67.com/" target="_blank" rel="noreferrer">
                <div className="flex flex-col items-start justify-center text-center">
                  <Typography
                    as="span"
                    className="w-full font-fraunces font-bold leading-snug tracking-widest underline"
                  >
                    {t('home:kumbh-2025.visit-camp67.cta')}
                  </Typography>
                  <Typography
                    variant="p"
                    as="span"
                    className="w-full text-wrap font-fraunces text-sm normal-case tracking-normal"
                  >
                    {t('home:kumbh-2025.visit-camp67.description')}
                  </Typography>
                </div>
              </a>
            </Button>
          </ContentSection>
        </div>
      </div>
      {/* ASY Section */}
      <div className="bg-accent py-16 text-accent-foreground" id="service-partners">
        <ContentSection className="flex flex-col items-center text-center">
          <Typography variant="h2">{t('home:asy.title')}</Typography>
          <Typography variant="h4" as="p" className="mt-4 max-w-3xl text-pretty">
            {t('home:asy.tagline')}
          </Typography>
        </ContentSection>
        <div className="my-12 flex justify-center">
          <AsyBrandCard />
        </div>
        <ContentSection className="mb-20 mt-24">
          <TestimonialGrid
            testimonials={[
              {
                imageSrc:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/8a4124f4-afaf-443f-bf47-64c5cd0fc500/public',
                imageAlt: t('home:asy.testimonials.sasha.imageAlt'),
                quote: t('home:asy.testimonials.sasha.quote'),
                author: {
                  name: 'Sasha',
                  location: t('home:asy.testimonials.sasha.author.location'),
                  videoSrc: 'https://www.youtube.com/embed/5tAELJmuiqY?si=Yugh0zFJGZOHnsCo',
                },
              },
              {
                imageSrc:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/33f70287-e512-4d98-9608-ab459bf51800/public',
                imageAlt: t('home:asy.testimonials.gunjan.imageAlt'),
                quote: t('home:asy.testimonials.gunjan.quote'),
                author: {
                  name: 'Gunjan Saini Bradshaw',
                  location: t('home:asy.testimonials.gunjan.author.location'),
                  videoSrc: 'https://www.youtube.com/embed/HRgHRCl9Sho?si=E3FytXIvFMnF8_Au',
                },
              },
            ]}
          />
        </ContentSection>
        <div className="mx-auto mb-24 w-4/5">
          <div className="flex w-full flex-col justify-center gap-6 md:flex-row">
            <AsyFeatureCard
              title={t('home:asy.features.idy2024.title')}
              description={t('home:asy.features.idy2024.description')}
              imageSrc={asyActivityIdy2024ImgUrl}
            />
            <AsyFeatureCard
              title={t('home:asy.features.idy2023.title')}
              description={t('home:asy.features.idy2023.description')}
              imageSrc={asyActivityIdyMauImgUrl}
            />
            <AsyFeatureCard
              title={t('home:asy.features.paryatanParv2019.title')}
              description={t('home:asy.features.paryatanParv2019.description')}
              imageSrc={asyActivityIncredibleIndiaImgUrl}
            />
          </div>
        </div>
        <ContentSection className="flex flex-col items-center text-center">
          <Typography variant="h2">{t('home:partners.title')}</Typography>
          <Typography variant="h4" as="p" className="mt-4 max-w-3xl text-pretty">
            <Trans i18nKey="home:partners.tagline">
              At Sukrit Wellness, we offer the opportunity to <strong>serve</strong> to all attendees at all locations,
              ranging from environmental impact to women empowerment and more.
            </Trans>
          </Typography>
        </ContentSection>
        <div className="mx-auto mb-12 mt-10 w-4/5">
          <div className="flex w-full flex-col justify-center gap-6 md:flex-row">
            <SocialServicesFeatureCard
              title={t('home:partners.asy.title')}
              description={t('home:partners.asy.description')}
              imageSrc={asyLogoImgUrl}
              websiteUrl="https://www.abhyasschoolofyoga.org/"
            />
            <SocialServicesFeatureCard
              title={t('home:partners.sadafal.title')}
              description={t('home:partners.sadafal.description')}
              imageSrc={sadafalEducationLogoImgUrl}
              websiteUrl="https://sadafal-education.com/"
            />
            <SocialServicesFeatureCard
              title={t('home:partners.bahudha.title')}
              description={t('home:partners.bahudha.description')}
              imageSrc={bahudhaFoundationLogoImgUrl}
              websiteUrl="https://bahudha-foundation.org/"
            />
          </div>
        </div>
      </div>
      {/* Our Team Section */}
      <div className="pt-16" id="our-team">
        <ContentSection className="mb-8 flex flex-col items-center text-center">
          <Typography variant="h2">{t('home:our-team.title')}</Typography>
          <Typography variant="h4" as="p" className="mt-4 max-w-3xl text-pretty">
            {t('home:our-team.tagline')}
          </Typography>
        </ContentSection>
        <div className="mx-auto md:px-4 lg:w-4/5 lg:px-0 xl:w-3/5">
          <div className="grid gap-8 md:grid-cols-3">
            <TeamFeatureCard
              title={t('home:our-team.naam-deo.title')}
              tagline={t('home:our-team.naam-deo.tagline')}
              description={t('home:our-team.naam-deo.description')}
              imageSrc={teamMemberNaamDeoImgUrl}
            />
            <TeamFeatureCard
              title={t('home:our-team.gangadhar.title')}
              tagline={t('home:our-team.gangadhar.tagline')}
              description={t('home:our-team.gangadhar.description')}
              imageSrc={teamMemberGangadharImgUrl}
              imageClassName="object-top"
            />
            <TeamFeatureCard
              title={t('home:our-team.robin.title')}
              tagline={t('home:our-team.robin.tagline')}
              description={t('home:our-team.robin.description')}
              imageSrc={teamMemberRobinImgUrl}
            />
            <TeamFeatureCard
              title={t('home:our-team.gopal.title')}
              tagline={t('home:our-team.gopal.tagline')}
              description={t('home:our-team.gopal.description')}
              imageSrc={teamMemberGopalImgUrl}
            />
            <TeamFeatureCard
              title={t('home:our-team.abhimanu.title')}
              tagline={t('home:our-team.abhimanu.tagline')}
              description={t('home:our-team.abhimanu.description')}
              imageSrc={CF_IMAGE_URLS['our-team-abhimanu-profile']}
            />
            <TeamFeatureCard
              title={t('home:our-team.manak.title')}
              tagline={t('home:our-team.manak.tagline')}
              description={t('home:our-team.manak.description')}
              imageClassName="object-top"
              imageSrc={CF_IMAGE_URLS['our-team-manak-profile']}
            />
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="mt-16">
        <div className="mb-12 flex flex-col items-center text-center">
          <Typography variant="h2">{t('home:enquiry.title')}</Typography>
          <Typography variant="h5" as="p" className="mt-1 max-w-3xl text-pretty font-sans">
            {t('home:enquiry.tagline')}
          </Typography>
        </div>
        <div className="relative bg-[#EDEBFF] pb-10 pt-28">
          <SubscribeForm />
          <div className="mx-auto flex w-[85%] flex-col gap-2 md:w-[60%]">
            <div className="flex flex-col justify-between gap-2 md:flex-row">
              <Typography variant="tagline" className="text-center md:text-left">
                {t('home:tagline')}
              </Typography>
              <SukritSocialRow />
            </div>
            <div className="">
              <Typography variant="h4" as="p" className="text-center text-base md:text-left">
                info@sukritwellness.com / +918860092457
              </Typography>
            </div>
            <Separator className="my-6 border-solid" />
            <div>
              <Typography variant="mutedText" className="text-center text-sm md:text-left">
                <Trans i18nKey="common:all-rights-reserved" currentYear={currentYear}>
                  All Rights Reserved {{ currentYear }}, Sukrit Wellness ©
                </Trans>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
