import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';
import { CF_IMAGE_URLS } from '~/lib/cf-image-urls';
import { cn } from '~/lib/utils';

export const CERTIFICATION_DATA = [
  {
    imageUrl: CF_IMAGE_URLS['certifications-ministry-ayush'],
    imageAlt: 'Ministry of Ayush, Government of India',
    className: 'max-h-16',
  },
  {
    imageUrl: CF_IMAGE_URLS['certifications-yoga-board'],
    imageAlt: 'Yoga Certification Board, Ministry of Ayush',
    className: 'max-h-16',
  },
  {
    imageUrl: CF_IMAGE_URLS['certifications-ficci'],
    imageAlt: 'Federation of Indian Chambers of Commerce & Industry',
    className: 'max-h-16',
  },
  {
    imageUrl: CF_IMAGE_URLS['certifications-incredible-india'],
    imageAlt: 'Incredible India',
    className: 'max-h-10',
  },
  {
    imageUrl: CF_IMAGE_URLS['certifications-inbound-tour-operator'],
    imageAlt: 'Inbound Tour Operator (Experienced), Ministry of Tourism',
    className: 'max-h-16',
  },
  {
    imageUrl: CF_IMAGE_URLS['certifications-ministry-tourism'],
    imageAlt: 'Ministry of Tourism, Government of India',
    className: 'max-h-16',
  },
  {
    imageUrl: CF_IMAGE_URLS['certifications-iatp'],
    imageAlt: 'Indian Association of Tour Operators, India',
    className: 'max-h-16',
  },
];

export function Certifications({
  certifications,
}: {
  certifications: { imageUrl: string; imageAlt: string; className?: string }[];
}) {
  return (
    <ul className="flex list-none flex-wrap items-center justify-center gap-8 md:flex md:flex-row">
      {certifications.map(({ imageUrl, imageAlt, className }) => (
        <li key={imageUrl} className="flex flex-col">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <img src={imageUrl} alt={imageAlt} className={cn('h-20 object-contain', className)} />
              </TooltipTrigger>
              <TooltipContent>{imageAlt}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </li>
      ))}
    </ul>
  );
}
